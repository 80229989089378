import React, { useCallback, useEffect, useState } from "react";
import { wingo_Url } from "../../../Context/Context";

export default function ChartThreeMinute({ triggerApiChartThreeMinute }) {
    const [chartData, setChartData] = useState([]);
    
    // Fetch data from the API
    const fetchChartData = useCallback(async () => {
        try {
        const response = await fetch(
            `${wingo_Url}/bet.php?action=lastHundredPeriodRecordForChart_3`
        );
        const json = await response.json();
        setChartData(json);
        } catch (error) {
        console.error("Error fetching chart data:", error);
        }
    }, []);

    // Use effect to load data
    useEffect(() => {
        fetchChartData();
    }, [triggerApiChartThreeMinute, fetchChartData]);

    return (
        <div className="Trend__C game-record">
            <div className="Trend__C-head">
                <div className="row">
                    <div className="col-6" style={{ fontSize: "15px" }}>Period</div>
                    <div className="col-6" style={{ fontSize: "15px" }}>Number</div>
                </div>
            </div>
            <div className="Trend__C-body1">
                <div className="Trend__C-body1-line pb-4 pt-4">
                    <strong style={{ fontSize: "16px" }}>Statistic (Last 100 Periods)</strong>
                </div>
                <div className="Trend__C-body1-line lottery">
                    <div>Winning number</div>
                    <div className="Trend__C-body1-line-num">
                        <div>0</div>
                        <div>1</div>
                        <div>2</div>
                        <div>3</div>
                        <div>4</div>
                        <div>5</div>
                        <div>6</div>
                        <div>7</div>
                        <div>8</div>
                        <div>9</div>
                    </div>
                </div>
                <div className="Trend__C-body1-line">
                    <div>Missing</div>
                    <div className="Trend__C-body1-line-num">
                        <div>17</div>
                        <div>8</div>
                        <div>4</div>
                        <div>10</div>
                        <div>13</div>
                        <div>1</div>
                        <div>5</div>
                        <div>3</div>
                        <div>0</div>
                        <div>14</div>
                    </div>
                </div>
                <div className="Trend__C-body1-line">
                    <div>Avg missing</div>
                    <div className="Trend__C-body1-line-num">
                        <div>10</div>
                        <div>10</div>
                        <div>6</div>
                        <div>10</div>
                        <div>13</div>
                        <div>9</div>
                        <div>10</div>
                        <div>6</div>
                        <div>10</div>
                        <div>8</div>
                    </div>
                </div>
                <div className="Trend__C-body1-line">
                    <div>Frequency</div>
                    <div className="Trend__C-body1-line-num">
                        <div>9</div>
                        <div>8</div>
                        <div>13</div>
                        <div>10</div>
                        <div>8</div>
                        <div>9</div>
                        <div>8</div>
                        <div>14</div>
                        <div>10</div>
                        <div>11</div>
                    </div>
                </div>
                <div className="Trend__C-body1-line">
                    <div>Max consecutive</div>
                    <div className="Trend__C-body1-line-num">
                        <div>2</div>
                        <div>1</div>
                        <div>1</div>
                        <div>2</div>
                        <div>2</div>
                        <div>1</div>
                        <div>1</div>
                        <div>2</div>
                        <div>2</div>
                        <div>2</div>
                    </div>
                </div>
            </div>
            <div className="Trend__C-body2">
                {chartData.map((item) => {
                    // Determine the background class for the answer (ans)
                    let ansBackgroundClass = "";
                    if (item.clo && item.res1) {
                        ansBackgroundClass = item.clo === "green" ? "GreenPlusViolet" : "RedPlusViolet";
                    } else {
                        ansBackgroundClass = item.clo === "green" ? "GreenResultOfChart" : item.clo === "red" ? "RedResultOfChart" : "";
                    }

                    const sizeClass = item.ans >= 5 ? "BigResultOfChart" : "SmallResultOfChart";

                    return (
                        <div key={item.id} className="row">
                            <div className="px-2">
                                <hr className="hrForchart" />
                            </div>
                            <div className="col-4">
                                <div className="Trend__C-body2-IssueNumber">{item.period}</div>
                            </div>
                            <div className="col-8">
                                <div
                                    className="Trend__C-body2-Num"
                                    style={{
                                        justifyContent: "right",
                                    }}
                                >
                                    <canvas id={`myCanvas0`} className="line-canvas"></canvas>
                                    {[...Array(10).keys()].map((number) => (
                                        <div
                                            key={number}
                                            className={`Trend__C-body2-Num-item ${
                                                number === parseInt(item.ans) ? ansBackgroundClass : ""
                                            }`}
                                        >
                                            {number}
                                        </div>
                                    ))}
                                    {/* Display S or B with proper class */}
                                    <div className={`Trend__C-body2-Num-BS ${sizeClass}`}>
                                        {item.ans >= 4 ? "B" : "S"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}