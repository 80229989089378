import { Route, Routes } from "react-router-dom"
import { useEffect, useState } from "react";
import "./App.css"
import { ProtectedComponent } from './Components/Protected/ProtectedComponent';
import Profile from "./Components/Profile/Profile"
import Win from "./Components/Win/Win"
import Search from "./Components/Search/Search"
import ForgotPassword from "./Components/SignIn&SignUp/ForgotPassword/ForgotPassword"
import SignIn from "./Components/SignIn&SignUp/SignIn/SignIn"
import SignUp from "./Components/SignIn&SignUp/SignUp/SignUp"
import Home from "./Home/Home"
import BankCard from "./Components/Profile/BankCard/BankCard"
import AddBankCard from "./Components/Profile/BankCard/AddBankCard/AddBankCard"
import Address from "./Components/Profile/Address/Address"
import AddADDRESS from "./Components/Profile/Address/AddADDRESS/AddADDRESS"
import Recharge from "./Components/Recharge/Recharge"
import USDT from "./Components/Recharge/USDT/USDT"
import Wallet from "./Components/Wallet/Wallet"
import Orders from "./Components/Profile/Orders/Orders"
import Reward from "./Components/Profile/Reward/Reward"
import Intrest from "./Components/Profile/Intrest/Intrest"
import RechargeRecord from "./Components/Recharge/RechargeRecord/RechargeRecord"
import Transactions from "./Components/Recharge/Transactions/Transactions"
import Complaints from "./Components/Profile/Complaints/Complaints"
import AddComplaints from "./Components/Profile/Complaints/AddComplaints/AddComplaints"
import Withdrawal from "./Components/Recharge/Withdrawal/Withdrawal"
import WithRecord from "./Components/Recharge/Withdrawal/WithRecord/WithRecord"
import Promotion from "./Components/Profile/Promotion/Promotion"
import Aviator from "./Components/Aviator/Aviator"
import LuckyHit from "./Components/LuckyHit/LuckyHit"
import Ludo from "./Components/Ludo/Ludo"
import Dice from "./Components/Dice/Dice"
import AndarBahar from "./Components/AndarBahar/AndarBahar"
import Mine from "./Components/Mine/Mine"
import WinOne from "./Components/WinOne/WinOne"
import Activity from "./Components/Activity/Activity"
import Gift from "./Components/Activity/Gift/Gift"
import DailyTasks from "./Components/Activity/DailyTasks/DailyTasks"
import VipIndex from "./Components/VIP/VipIndex"
import NoInternet from "./Components/NoInternet/NoInternet";

function App() {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  
  useEffect(() => {
    const updateOnlineStatus = () => setIsOnline(navigator.onLine);
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  window.$loginData = localStorage.getItem("loginData");

  if (!isOnline) {
    return <NoInternet />;
  }

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/signin" element={<ProtectedComponent element={SignIn} />} />
        <Route path="/signup" element={<ProtectedComponent element={SignUp} />} />
        <Route path="/forgotpassword" element={<ProtectedComponent element={ForgotPassword} />} />
        <Route path="/win" element={<ProtectedComponent element={Win} />} />
        <Route path="/search" element={<ProtectedComponent element={Search} />} />
        <Route path="/profile" element={<ProtectedComponent element={Profile} />} />
        <Route path="/promotion" element={<ProtectedComponent element={Promotion} />} />
        <Route path="/bankcard" element={<ProtectedComponent element={BankCard} />} />
        <Route path="/addbankcard" element={<ProtectedComponent element={AddBankCard} />} />
        <Route path="/address" element={<ProtectedComponent element={Address} />} />
        <Route path="/addaddress" element={<ProtectedComponent element={AddADDRESS} />} />
        <Route path="/orders" element={<ProtectedComponent element={Orders} />} />
        <Route path="/reward" element={<ProtectedComponent element={Reward} />} />
        <Route path="/intrest" element={<ProtectedComponent element={Intrest} />} />
        <Route path="/complaints" element={<ProtectedComponent element={Complaints} />} />
        <Route path="/addcomplaints" element={<ProtectedComponent element={AddComplaints} />} />
        <Route path="/recharge" element={<ProtectedComponent element={Recharge} />} />
        <Route path="/USDT" element={<ProtectedComponent element={USDT} />} />
        <Route path="/Wallet" element={<ProtectedComponent element={Wallet} />} />
        <Route path="/rechargerecord" element={<ProtectedComponent element={RechargeRecord} />} />
        <Route path="/withdrawal" element={<ProtectedComponent element={Withdrawal} />} />
        <Route path="/withrecord" element={<ProtectedComponent element={WithRecord} />} />
        <Route path="/transactions" element={<ProtectedComponent element={Transactions} />} />
        <Route path="/aviator" element={<ProtectedComponent element={Aviator} />} />
        <Route path="/luckyhit" element={<ProtectedComponent element={LuckyHit} />} />
        <Route path="/ludo" element={<ProtectedComponent element={Ludo} />} />
        <Route path="/dice" element={<ProtectedComponent element={Dice} />} />
        <Route path="/andarbahar" element={<ProtectedComponent element={AndarBahar} />} />
        <Route path="/mine" element={<ProtectedComponent element={Mine} />} />
        <Route path="/winone" element={<ProtectedComponent element={WinOne} />} />
        <Route path="/Activity" element={<ProtectedComponent element={Activity} />} />
        <Route path="/DailyTasks" element={<ProtectedComponent element={DailyTasks} />} />
        <Route path="/Gift" element={<ProtectedComponent element={Gift} />} />
        <Route path="/VipIndex" element={<ProtectedComponent element={VipIndex} />} />
      </Routes>
    </>
  )
}

export default App