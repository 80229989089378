import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function BottomNav() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);

  const handleClick = (tabIndex, path) => {
    setActiveTab(tabIndex);
    navigate(path);
  };

  const redirectMine = () => {
    if (window.$loginData !== null) {
      handleClick(2, "/profile");
    } else {
      navigate("/signin");
    }
  };

  return (
    <div className="bottomNav">
      <img src="nnbottom.png" alt="" />
      <div
        className={`botInfo ${activeTab === 0 ? "activeTab" : ""}`}
        style={{ left: "10px" }}
        onClick={() => handleClick(0, "/")}
      >
        <div className="botImg">
          <i className="fa fa-home"></i>
        </div>
        <h4>Home</h4>
      </div>
      <div
        className={`botInfo ${activeTab === 3 ? "activeTab" : ""}`}
        style={{ left: "83px" }}
        onClick={() => handleClick(3, "/Activity")}
      >
        <div className="botImg">
          <i className="fa fa-bolt"></i>
        </div>
        <h4>Activity</h4>
      </div>
      <div
        className={`botInfo ${activeTab === 1 ? "activeTab" : ""}`}
        style={{ width: "fit-content", margin: "auto", left: 0, right: 0 }}
        onClick={() => handleClick(1, "/promotion")}
      >
        <div className="botImg">
          <img src="dssa.png" alt="Diamond Icon" />
        </div>
        <h5>Promotion</h5>
      </div>
      <div
        className={`botInfo ${activeTab === 4 ? "activeTab" : ""}`}
        style={{ right: "90px" }}
        onClick={() => handleClick(4, "/wallet")}
      >
        <div className="botImg">
          <i className="fa fa-wallet"></i>
        </div>
        <h4>Wallet</h4>
      </div>
      <div
        className={`botInfo ${activeTab === 2 ? "activeTab" : ""}`}
        style={{ right: "10px" }}
        onClick={redirectMine}
      >
        <div className="botImg">
          <i className="fa fa-user"></i>
        </div>
        <h4>Account</h4>
      </div>
    </div>
  );
}
