import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress, Box, Typography } from '@mui/material';
import axios from "axios";
import { Fetch_Url } from "../../Context/Context";

export default function Wallet() {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [UserTotalBalance, setUserTotalBalance] = useState([]);
  const [AllTimeDepositedAmount, setAllTimeDepositedAmount] = useState("");

  // Fetch user info
  const getUserinfo = async () => {
    try {
      const response = await axios.post(
        `${Fetch_Url}/me.php?action=getuserinfo&user=${window?.$loginData}`,
        {}
      );
      setUserTotalBalance(response?.data?.user_Data[0]?.balance || []);
      setAllTimeDepositedAmount(response?.data?.user_Data?.[5]?.total_deposit || 0);
    } catch (err) {
      console.error("Error fetching user info:", err);
    }
  };

  useEffect(() => {
    getUserinfo();
  }, []);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1));
    }, 30); // Adjust this value to control the speed

    return () => clearInterval(interval);
  }, []);

  return (
    <>
        <div className="minibg">
        <div className="miniHeadforWallet">
            <i
            className="fa fa-arrow-left"
            onClick={() => {
                navigate(-1);
            }}
            ></i>
            <h3>Wallet</h3>
        </div>
        <div class="wallet-container-header">
            <div class="wallet-container-header-belly">
                <img src="https://static.vecteezy.com/system/resources/thumbnails/011/107/367/small_2x/wallet-3d-icon-illustration-png.png" />
                <div>
                    ₹{UserTotalBalance.toLocaleString()}
                </div>
                <span>
                    Total balance
                </span>
                <div>
                  <div>
                      <p className="total">
                        ₹{UserTotalBalance.toLocaleString()}
                      </p>
                      <p style={{ fontSize: '12px' }}>
                          Total amount
                      </p>
                  </div>
                  <div>
                      <p className="total">
                        ₹{AllTimeDepositedAmount.toLocaleString()}
                      </p>
                      <p style={{ fontSize: '12px' }}>
                          Total deposit amount
                      </p>
                  </div>
                </div>
            </div>
        </div>
        </div>

      <div className="WalletbalCont">
        <div className="circleFlex">
            <div style={{ alignItems: 'center', textAlign: 'center' }}>
            <Box position="relative">
                <CircularProgress variant="determinate" value={progress} size={140} sx={{ color: '#f2403a' }} />
                <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
                >
                <Typography variant="caption" component="div" color="textSecondary" sx={{ fontSize: '25px', color: "#f2403a", fontWeight: "bold" }}>
                    {`${Math.round(progress)}%`}
                </Typography>
                </Box>
            </Box>
            <br />
            <h4>
              ₹{UserTotalBalance.toLocaleString()}
            </h4>
            <h6>
                Main Wallet
            </h6>
            </div>
            <div style={{ alignItems: 'center', textAlign: 'center' }}>
          <Box position="relative">
            <CircularProgress variant="determinate" value={progress} size={140} sx={{ color: '#f2403a' }} />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="caption" component="div" color="textSecondary" sx={{ fontSize: '25px', color: "#f2403a", fontWeight: "bold" }}>
                {`${Math.round(progress)}%`}
              </Typography>
            </Box>
          </Box>
          <br />
            <h4>
                ₹0
            </h4>
            <h6>
                3rd Party Wallet
            </h6>
            </div>
        </div>
        <div className="px-4">
            <button className="gradient-bg-btn">
                Main Wallet Transfer
            </button>
        </div>
        <div className=" wallet-grid">
            <div className=" wallet-card" onClick={() => navigate('/recharge')}>
              <img
                src="happy/RechargeIcon.png"
                className=" wallet-image"
              />
              <p className=" wallet-title">
                Deposit
              </p>
            </div>
            <div className=" wallet-card" onClick={() => navigate('/Withdrawal')}>
              <img
                src="happy/WithdrawalIcon.png"
                className=" wallet-image"
              />
              <p className=" wallet-title">
                Withdraw
              </p>
            </div>
            <div className=" wallet-card" onClick={() => navigate('/RechargeRecord')}>
              <img
                src="happy/RechargeHistoryIcon.png"
                className=" wallet-image"
              />
              <p className=" wallet-title">
                Deposit History
              </p>
            </div>
            <div className=" wallet-card" onClick={() => navigate('/WithRecord')}>
              <img
                src="happy/WithdrawalHistoryIcon.png"
                className=" wallet-image"
              />
              <p className=" wallet-title">
                Withdrawal History
              </p>
            </div>
        </div>
      </div>
    </>
  );
}