import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CircularProgress, Box, Typography } from '@mui/material';
import axios from "axios";
import { Fetch_Url } from "../../Context/Context";

export default function Recharge() {
  const navigate = useNavigate();
  const [balinput, setBalinput] = useState("");
  const [progress, setProgress] = useState(0);
  const [userdetail, setUserdetail] = useState([]);

  // Fetch user info
  const getUserinfo = async () => {
    try {
      const response = await axios.post(
        `${Fetch_Url}/me.php?action=getuserinfo&user=${window?.$loginData}`,
        {}
      );
      setUserdetail(response?.data?.user_Data || []);
    } catch (err) {
      console.error("Error fetching user info:", err);
    }
  };

  useEffect(() => {
    getUserinfo();
  }, []);

  const bal = userdetail[0]?.balance || 0;  // Access the first user detail object for balance
  
  // Handle deposit redirection
  const redirectUrl = () => {
    navigator.vibrate(100);
    if (balinput > 99) {
      window?.open(
        `https://demo.games350.com/trova/src/api/pay.php?user=${window?.$loginData}&&am=${balinput}`,
        "_blank"
      );
    } else {
      toast.error("Input Empty Or Recharge Value at least 100", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  // Handle progress animation for the deposit
  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1));
    }, 30);  // Adjust this value to control the speed
    return () => clearInterval(interval);
  }, []);

  const RechargeButtons = [
    { amount: "100", title: "100" },
    { amount: "200", title: "200" },
    { amount: "500", title: "500" },
    { amount: "1000", title: "1K" },
    { amount: "5000", title: "5K" },
    { amount: "10000", title: "10K" },
  ];

  const redirectToUSDT = () => {
    navigate('/USDT');
  };

  return (
    <>
      <ToastContainer />
      <div className="miniHeadForBg">
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <h3>Deposit</h3>
        <i
          className="fa fa-align-left"
          onClick={() => {
            navigate("/rechargerecord");
          }}
        ></i>
      </div>
      <div>
        <div className="px-2">
          <div class="mt-2 product-conatiner-deposit" style={{ position: 'relative' }}>
            <div className="pt-2">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img 
                  style={{ width: '2rem' }} 
                  src="happy/BalanceWallet.png" 
                />
                <span 
                  className="text-white" 
                  style={{ marginLeft: '5px', fontSize: '18px', alignSelf: 'center' }}
                >
                  Balance
                </span>
              </div>
              <h3 className="pt-2 pl-2 text-white" style={{ display: 'flex', alignItems: 'center' }}>
                <b style={{ fontSize: '20px', marginRight: '8px' }}>
                  ₹{bal}
                </b>
                <i className="fa fa-refresh" onClick={getUserinfo} style={{ fontSize: '15px' }}></i>
              </h3>
            </div>
            <div className="" style={{ marginTop: '100px' }}>
              <span style={{ paddingRight: '8px', color: "#fff", fontSize: '20px', position: 'absolute', bottom: '0', right: '0' }}>
                **** **** **** ****
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="payment-method-grid">
        <div className="payment-method-card pt-4">
          <img
            src="happy/UPI.png"
            alt="UPI"
            className="payment-method-image"
            style={{ width: '30px', height: '30px' }}
          />
          <p className="payment-method-title">
            UPI
          </p>
        </div>
        <div className="payment-method-card upi-qr-bg px-5 pt-4">
          <img
            src="happy/UPI-QR.png"
            alt="UPI-QR"
            className="payment-method-image"
            style={{ width: '30px', height: '30px' }}
          />
          <p className="payment-method-title" style={{ color: '#fff', fontSize: '15px' }}>
            UPI-QR
          </p>
        </div>
        <div className="payment-method-card pt-4" onClick={redirectToUSDT}>
          <img
            src="happy/USDT.png"
            alt="USDT"
            className="payment-method-image"
            style={{ width: '30px', height: '30px' }}
          />
          <p className="payment-method-title">
            USDT
          </p>
        </div>
      </div>
      <div className="px-2">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img 
            style={{ width: '1.7rem' }} 
            src="happy/Deposite.png" 
          />
          <span 
            className="text-dark" 
            style={{ marginLeft: '5px', marginTop: '5px', fontWeight: '600', fontSize: '15px', alignSelf: 'center' }}
          >
            Deposit Amount
          </span>
        </div>
      </div>
      <div className="mt-3 px-2">
        <div className="row">
          {RechargeButtons.map((RechargeButton, index) => (
            <div key={index} className="col-4">
              <button className="recharge-btn" onClick={() => setBalinput(RechargeButton?.amount)} style={{ width: '100%' }}>
                ₹{RechargeButton?.title}
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="px-2">
        <div class="">
          <label class="myinput-group">
            <div class="prepend">
              <i class="fa fa-rupee"></i>
            </div>
            <input
              type="number"
              placeholder="Please Enter The Amount" 
              value={balinput}
              onChange={(e) => {
                setBalinput(e.target.value);
              }}
            />
          </label>
        </div>
        <div className="mt-3">
          <button className="gradient-bg-btn" onClick={redirectUrl}>
            Deposit
          </button>
        </div>
      </div>
      <div className="mt-3 px-2">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img 
            style={{ width: '1.5rem' }} 
            src="happy/Book.png" 
          />
          <span 
            className="text-dark" 
            style={{ marginLeft: '5px', marginTop: '0px', fontWeight: '600', fontSize: '15px', alignSelf: 'center' }}
          >
            Recharge Instructions
          </span>
        </div>
      </div>
      <div className="mt-3 px-2">
        <div style={{ borderRadius: '10px', border: '1px solid #ccc', display: 'flex', alignItems: 'center' }}>
          <div className="mt-1 mb-2">
            <span 
              style={{ marginLeft: '5px', color: '#aaa', marginBottom: '5px', fontSize: '12px', display: 'flex', alignItems: 'center' }}
            >
              <b className="gradient-text" style={{ fontSize: '15px', marginRight: '5px' }}>🟐</b> 
              If the transfer time is up, please fill out the deposit form again.
            </span>
            <span 
              style={{ marginLeft: '5px', color: '#aaa', marginBottom: '5px', fontSize: '12px', display: 'flex', alignItems: 'center' }}
            >
              <b className="gradient-text" style={{ fontSize: '15px', marginRight: '5px' }}>🟐</b> 
              The transfer amount must match the order you created, otherwise the money cannot be credited successfully.
            </span>
            <span 
              style={{ marginLeft: '5px', color: '#aaa', marginBottom: '5px', fontSize: '12px', display: 'flex', alignItems: 'center' }}
            >
              <b className="gradient-text" style={{ fontSize: '15px', marginRight: '5px' }}>🟐</b> 
              If you transfer the wrong amount, our company will not be responsible for the lost amount!
            </span>
            <span 
              style={{ marginLeft: '5px', color: '#aaa', fontSize: '12px', display: 'flex', alignItems: 'center' }}
            >
              <b className="gradient-text" style={{ fontSize: '15px', marginRight: '5px' }}>🟐</b> 
              Note: do not cancel the deposit order after the money has been transferred.
            </span>
          </div>
        </div>
      </div>
      <br />
    </>
  );
}