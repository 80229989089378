import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

export function ProtectedComponent({ element: Component }) {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const loginDetails = localStorage.getItem("loginData");

        if (!loginDetails && location.pathname !== "/signin") {
            navigate("/signin");
        } else if (loginDetails && location.pathname === "/signin") {
            navigate("/");
        }
    }, [location, navigate]);

    return <Component />;
}
