import BottomNav from "../BottomNavigate/BottomNav";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// mui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from "@mui/material/Paper";
import { lucky_Url } from "../../Context/Context";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactAudioPlayer from "react-audio-player";
import cardA from "../LuckyHit/card A.jpg"
import cardK from "../LuckyHit/card K.jpg"
import AAK from "../LuckyHit/AAK.jpg"
import KKK from "../LuckyHit/KKK.jpg"
import KKQ from "../LuckyHit/KKQ.jpg"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  border: "0px solid white",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const moneydata = [
  {
    id: 1,
    money: 10,
  },
  {
    id: 2,
    money: 100,
  },
  {
    id: 3,
    money: 1000,
  },
  {
    id: 4,
    money: 10000,
  },
];

const numData = [
  {
    id: 1,
    img: "AAK.jpg",
    str: "AAK"
  },
  {
    id: 2,
    img: "AAQ.jpg",
    str: "AAQ"
  },
  {
    id: 3,
    img: "AKQ.jpg",
    str: "AKQ"
  },
  {
    id: 4,
    img: "JJJ.jpg",
    str: "JJJ"
  },
  {
    id: 5,
    img: "KKA.jpg",
    str: "KKA"
  },
  {
    id: 6,
    img: "KKQ.jpg",
    str: "KKQ"
  },
  {
    id: 7,
    img: "QQA.jpg",
    str: "QQA"
  },
  {
    id: 8,
    img: "QQK.jpg",
    str: "QQK"
  },
];

export default function LuckyHit() {
  const navigate = useNavigate()
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [openNum, setOpenNum] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [userdetailWin, setUserdetailWin] = useState([]);
  const [resdata, setResdata] = useState([]);
  const [betdata, setBetdata] = useState([]);
  const [seconds, setSeconds] = useState(180);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const [page, setPage] = useState(1);
  const [pageRes, setPageRes] = useState(1);
  const [randomData, setRandomData] = useState([]);
  const [randomData2, setRandomData2] = useState([]);
  const [resultinfo, setResultinfo] = useState([]);
  const [slotItems, setSlotItems] = useState([cardA, cardK, AAK, KKK, KKQ]);
  // const [randomresult, setRandomresult] = useState(null);
  const [position1, setPosition1] = useState(0);
  const [position2, setPosition2] = useState(-1);
  const [position3, setPosition3] = useState(-2);
  const [intvalue, setIntvalue] = useState(1);
  const [intvalue2, setIntvalue2] = useState(1);
  const [intvalue3, setIntvalue3] = useState(1);
  const [intvalue4, setIntvalue4] = useState(1);
  const [greenbtn, setGreenbtn] = useState({
    id: 1,
    money: 10,
  });
  const [violetbtn, setVioletbtn] = useState({
    id: 1,
    money: 10,
  });
  const [redbtn, setRedbtn] = useState({
    id: 1,
    money: 10,
  });
  const [bluebtn, setBluebtn] = useState({
    id: 1,
    money: 10,
  });

  window.$loginData = localStorage.getItem("loginData");
  // console.log(window.$loginData, "logindata");

  // console.log(intvalue, greenbtn, "intvalue, greenbtn");
  let contractMoney = greenbtn?.money * intvalue;
  // console.log(contractMoney, "contractMoney");

  // console.log(intvalue2, violetbtn, "intvalue2, violetbtn");
  let contractMoney2 = violetbtn?.money * intvalue2;
  // console.log(contractMoney2, "contractMoney2");

  // console.log(intvalue3, redbtn, "intvalue3, redbtn");
  let contractMoney3 = redbtn?.money * intvalue3;
  // console.log(contractMoney3, "contractMoney3");

  // console.log(intvalue4, bluebtn, "intvalue4, bluebtn");
  let contractMoney4 = bluebtn?.money * intvalue4;
  // console.log(contractMoney4, "contractMoney4");

  const handleChange = (Item) => (event, isExpanded) => {
    setExpanded(isExpanded ? Item : false);
  };

  const handleChange2 = (event, newValue) => {
    setValue(newValue);
  };

  const userInfo = () => {
    axios
      .post(`${lucky_Url}/bet.php?action=info&user=${window.$loginData}`, {})
      .then((resp) => {
        console.log(resp, "resp userInfo data");
        setUserdetailWin(resp?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const luckyResult = () => {
    axios
      .post(`${lucky_Url}/declaredResult.php`, {
        aksi: "proses_rand_card",
        period: periodID?.period,
      })
      .then((resp) => {
        console.log(resp, "resp luckyResult data");
        setResultinfo(resp?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [bal] = userdetailWin;
  // console.log(bal, "bal");

  const periodID = userdetailWin?.find((item) => {
    return item?.period;
  });
  // console.log(periodID, "periodID");

  const resultRecord = React.useCallback(async () => {
    const res = fetch(
      `${lucky_Url}/bet.php?action=resultrec&page=${pageRes}`
    ).then((res) => res.json());
    const json = await res;
    console.log(json, "res result data");
    setResdata(json);
  }, [pageRes]);

  useEffect(() => {
    resultRecord();
  }, [resultRecord]);

  const betRecord = React.useCallback(async () => {
    const res = fetch(
      `${lucky_Url}/bet.php?action=betrec&user=${window?.$loginData}&page1=${page}`
    ).then((res) => res.json());
    const json = await res;
    console.log(json, "res bet data");
    setBetdata(json);
  }, [page]);

  React.useEffect(() => {
    betRecord();
  }, [betRecord]);

  const updatePeriod = () => {
    axios
      .post(`${lucky_Url}/declaredResult.php`, {
        aksi: "Update_PeriodId"
      })
      .then((resp) => {
        console.log(resp, "resp updatePeriod data");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const joinGreen = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID?.period);
    formdata.append("amount", contractMoney);
    formdata.append("ans", "AAA");
    if (contractMoney <= bal?.balance) {
      axios
        .post(`${lucky_Url}/bet.php?action=bet`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinGreen resp data");
          if (resp?.data[0]?.status === "Bet Added Successfully") {
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
            setOpen(false);
            userInfo()
            betRecord()
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  };

  const joinViolet = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID?.period);
    formdata.append("amount", contractMoney2);
    formdata.append("ans", "KKK");
    contractMoney2 <= bal?.balance
      ? axios
        .post(`${lucky_Url}/bet.php?action=bet`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinViolet resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpen2(false);
          userInfo()
          betRecord()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const joinRed = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID?.period);
    formdata.append("amount", contractMoney3);
    formdata.append("ans", "QQQ");
    contractMoney3 <= bal?.balance
      ? axios
        .post(`${lucky_Url}/bet.php?action=bet`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinRed resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpen3(false);
          userInfo()
          betRecord()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const joinBlue = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata.append("username", window.$loginData);
    formdata.append("period", periodID?.period);
    formdata.append("amount", contractMoney4);
    formdata.append("ans", openNum?.str);
    contractMoney4 <= bal?.balance
      ? axios
        .post(`${lucky_Url}/bet.php?action=bet`, formdata, {})
        .then((resp) => {
          // console.log(resp, "joinBlue resp data");
          resp?.data[0]?.status === "Bet Added Successfully" &&
            toast.success("Bet Added Successfully", {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          setOpenNum(false);
          userInfo()
          betRecord()
        })
        .catch((err) => {
          console.log(err);
        })
      : toast.error("Insufficient Balance", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
  };

  const generateRandomData = () => {
    const newData = [];
    const newData2 = [];
    for (let i = 0; i < 30; i++) {
      // Generate your random data here
      const randomValue = (Math?.floor(100000 + Math?.random() * 900000));
      const randomValue2 = (Math?.floor(1000 + Math?.random() * 9000));
      newData?.push(randomValue);
      newData2?.push(randomValue2);
    }
    setRandomData(newData);
    setRandomData2(newData2);
  };

  useEffect(() => {
    let interval = null;

    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    const DateValue = Date.parse(new Date()) / 1e3;
    const h = 180 - (DateValue % 180);
    var i = h / 60,
      n = h % 60;
    setMinute(Math.floor(i));
    setSecond(("0" + Math.floor(n)).slice(-2));
  });
  // console.log(minute, second, "minute, second");

  useEffect(() => {
    if (minute === 0 && second < "30") {
      setOpen(false);
      setOpen2(false);
      setOpen3(false);
      setOpenNum(false);
    }

    if (minute === 3 && second === "00") {
      window?.location?.reload(false);
    }

    if (minute === 0 && second === "10") {
      luckyResult()
    }

    if (minute === 0 && second === "01") {
      updatePeriod()
    }
  }, [minute, second]);

  useEffect(() => {
    userInfo();
    generateRandomData()
  }, []);

  const updatePositions = () => {
    setPosition1(prevPosition => (prevPosition === slotItems?.length - 1 ? 0 : prevPosition + 1));
    setPosition2(prevPosition => (prevPosition === slotItems?.length - 1 ? 0 : prevPosition + 1));
    setPosition3(prevPosition => (prevPosition === slotItems?.length - 1 ? 0 : prevPosition + 1));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      updatePositions();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // const createResult = () => {
  //   const random = Math.floor(Math.random() * 5) + 1;
  //   setRandomresult(random);
  // };

  return (
    <>
      <ToastContainer />
      <BottomNav />
      <div className="gameTop" style={{ background: "linear-gradient(180deg,#ce0204,#f2403a)" }}>
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate("/");
          }}
        ></i>
        <h3>Lucky Hit</h3>
        <h4>
          &#8377; {bal?.balance}
        </h4>
        <i
          className="fa fa-credit-card"
          onClick={() => {
            navigate("/recharge", { state: bal }
            );
          }}
        ></i>
      </div>
      <div className="winCont">
        <div className="winParity">
          <h5>Lucky Hit</h5>
        </div>
        <div className="luckyTime">
          <div className="luckyTxt">
            <h5>Period ID</h5>
            <h6>{periodID?.period}</h6>
          </div>
          <div className="lcukyCount">
            <h5>Time Remaining</h5>
            <h3>
              <span>0{minute}</span> <span>:</span> <span>{second}</span>
            </h3>
          </div>
        </div>
        {minute === 0 && second < 6 && (
          <div className="singleImg">
            <h4>Result</h4>
            <img src={`${resultinfo?.card_name}.jpg`} alt="" />
            <hr />
          </div>
        )}
        {minute === 0 && second > 5 && second < 31 && (
          <div className="scroll-container">
            {(slotItems[position1] !== undefined && slotItems[position2] !== undefined && slotItems[position3] !== undefined) &&
              <div className="scrollingImg">
                <img src={slotItems[position1]} alt="" />
                <img src={slotItems[position2]} alt="" />
                <img src={slotItems[position3]} alt="" />
              </div>
            }
          </div>
        )}
        <div className="winMinicont">
          <div className="joinBtn">
            <div className="luckyImg">
              <img src="card A.jpg" alt=""
                onClick={() => {
                  minute === 0 && second < "30"
                    ? setOpen(false)
                    : setOpen(true);
                }}
              />
              <Modal
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="numHead">
                    <h2>Join AAA</h2>
                  </div>
                  <div className="greenHeadBox">
                    <div className="greenHeadTxt">
                      <h5>Contract Money</h5>
                    </div>
                    <div className="greenHeadBtn">
                      {moneydata?.map((item) => {
                        return (
                          <>
                            <div
                              className={
                                item?.id === greenbtn?.id ? "clickedColor" : ""
                              }
                            >
                              <button
                                onClick={() => {
                                  setGreenbtn(item);
                                }}
                              >
                                {item?.money}
                              </button>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="greenHeadTxt">
                      <h5>Number</h5>
                    </div>
                    <div className="greenHeadCount">
                      <button
                        disabled={intvalue <= 1}
                        onClick={() => {
                          setIntvalue(intvalue - 1);
                        }}
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                      <h3>{intvalue}</h3>
                      <button
                        onClick={() => {
                          setIntvalue(intvalue + 1);
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                    <div className="greenHeadTxt">
                      <h5>Total Contract Money is {contractMoney}</h5>
                    </div>
                    <div className="greenHeadTxt2">
                      <h4>
                        <i className="fa fa-check"></i>I agree
                        <span>PRESALE RULE</span>
                      </h4>
                    </div>
                    <div className="signinModalBtn">
                      <button
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        CANCEL
                      </button>
                      <button onClick={joinGreen}>CONFIRM</button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
            <div className="luckyImg2">
              <img src="card K.jpg" alt=""
                onClick={() => {
                  minute === 0 && second < "30"
                    ? setOpen2(false)
                    : setOpen2(true);
                }}
              />
              <Modal
                open={open2}
                onClose={() => {
                  setOpen2(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="numHead">
                    <h2>Join KKK</h2>
                  </div>
                  <div className="greenHeadBox">
                    <div className="greenHeadTxt">
                      <h5>Contract Money</h5>
                    </div>
                    <div className="greenHeadBtn">
                      {moneydata?.map((item) => {
                        return (
                          <>
                            <div
                              className={
                                item?.id === violetbtn?.id ? "clickedColor" : ""
                              }
                            >
                              <button
                                onClick={() => {
                                  setVioletbtn(item);
                                }}
                              >
                                {item?.money}
                              </button>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="greenHeadTxt">
                      <h5>Number</h5>
                    </div>
                    <div className="greenHeadCount">
                      <button
                        disabled={intvalue2 <= 1}
                        onClick={() => {
                          setIntvalue2(intvalue2 - 1);
                        }}
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                      <h3>{intvalue2}</h3>
                      <button
                        onClick={() => {
                          setIntvalue2(intvalue2 + 1);
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                    <div className="greenHeadTxt">
                      <h5>Total Contract Money is {contractMoney2}</h5>
                    </div>
                    <div className="greenHeadTxt2">
                      <h4>
                        <i className="fa fa-check"></i>I agree
                        <span>PRESALE RULE</span>
                      </h4>
                    </div>
                    <div className="signinModalBtn">
                      <button
                        onClick={() => {
                          setOpen2(false);
                        }}
                      >
                        CANCEL
                      </button>
                      <button onClick={joinViolet}>CONFIRM</button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
            <div className="luckyImg3">
              <img src="card Q.jpg" alt=""
                onClick={() => {
                  minute === 0 && second < "30"
                    ? setOpen3(false)
                    : setOpen3(true);
                }}
              />
              <Modal
                open={open3}
                onClose={() => {
                  setOpen3(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <div className="numHead">
                    <h2>Join QQQ</h2>
                  </div>
                  <div className="greenHeadBox">
                    <div className="greenHeadTxt">
                      <h5>Contract Money</h5>
                    </div>
                    <div className="greenHeadBtn">
                      {moneydata?.map((item) => {
                        return (
                          <>
                            <div
                              className={
                                item?.id === redbtn?.id ? "clickedColor" : ""
                              }
                            >
                              <button
                                onClick={() => {
                                  setRedbtn(item);
                                }}
                              >
                                {item?.money}
                              </button>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="greenHeadTxt">
                      <h5>Number</h5>
                    </div>
                    <div className="greenHeadCount">
                      <button
                        disabled={intvalue3 <= 1}
                        onClick={() => {
                          setIntvalue3(intvalue3 - 1);
                        }}
                      >
                        <i className="fa fa-minus"></i>
                      </button>
                      <h3>{intvalue3}</h3>
                      <button
                        onClick={() => {
                          setIntvalue3(intvalue3 + 1);
                        }}
                      >
                        <i className="fa fa-plus"></i>
                      </button>
                    </div>
                    <div className="greenHeadTxt">
                      <h5>Total Contract Money is {contractMoney3}</h5>
                    </div>
                    <div className="greenHeadTxt2">
                      <h4>
                        <i className="fa fa-check"></i>I agree
                        <span>PRESALE RULE</span>
                      </h4>
                    </div>
                    <div className="signinModalBtn">
                      <button
                        onClick={() => {
                          setOpen3(false);
                        }}
                      >
                        CANCEL
                      </button>
                      <button onClick={joinRed}>CONFIRM</button>
                    </div>
                  </div>
                </Box>
              </Modal>
            </div>
          </div>
          <div className="winImg">
            {numData?.map((item) => {
              return (
                <>
                  <img src={item?.img} alt=""
                    onClick={() => {
                      minute === 0 && second < "30"
                        ? setOpenNum(false)
                        : setOpenNum(true) || setOpenNum(item);
                    }}
                  />
                </>
              );
            })}
            <Modal
              open={openNum}
              onClose={() => {
                setOpenNum(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <div className="numHead">
                  <h2>Join {openNum?.str}</h2>
                </div>
                <div className="greenHeadBox">
                  <div className="greenHeadTxt">
                    <h5>Contract Money</h5>
                  </div>
                  <div className="greenHeadBtn">
                    {moneydata?.map((item) => {
                      return (
                        <>
                          <div
                            className={
                              item?.id === bluebtn?.id ? "clickedColor" : ""
                            }
                          >
                            <button
                              onClick={() => {
                                setBluebtn(item);
                              }}
                            >
                              {item?.money}
                            </button>
                          </div>
                        </>
                      );
                    })}
                  </div>
                  <div className="greenHeadTxt">
                    <h5>Number</h5>
                  </div>
                  <div className="greenHeadCount">
                    <button
                      disabled={intvalue4 <= 1}
                      onClick={() => {
                        setIntvalue4(intvalue4 - 1);
                      }}
                    >
                      <i className="fa fa-minus"></i>
                    </button>
                    <h3>{intvalue4}</h3>
                    <button
                      onClick={() => {
                        setIntvalue4(intvalue4 + 1);
                      }}
                    >
                      <i className="fa fa-plus"></i>
                    </button>
                  </div>
                  <div className="greenHeadTxt">
                    <h5>Total Contract Money is {contractMoney4}</h5>
                  </div>
                  <div className="greenHeadTxt2">
                    <h4>
                      <i className="fa fa-check"></i>I agree
                      <span>PRESALE RULE</span>
                    </h4>
                  </div>
                  <div className="signinModalBtn">
                    <button
                      onClick={() => {
                        setOpenNum(false);
                      }}
                    >
                      CANCEL
                    </button>
                    <button onClick={joinBlue}>CONFIRM</button>
                  </div>
                </div>
              </Box>
            </Modal>
          </div>
        </div>
        <Box
          sx={{
            width: "100%",
            marginTop: "15px"
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange2}
            aria-label="basic tabs example"
            className="luckyTabs"
          >
            <Tab label="LuckyHit Records" {...a11yProps(0)} />
            <Tab label="All Records" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className="resCont">
            <div className="winRecord">
              <i className="fa fa-trophy"></i>
              <h5>LuckyHit Record</h5>
            </div>
            <Paper>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Period</TableCell>
                      <TableCell align="right">Price</TableCell>
                      {/* <TableCell align="right">Select</TableCell> */}
                      <TableCell align="right">Result</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resdata?.map((item) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {item?.period_id}
                        </TableCell>
                        <TableCell align="right">{item?.price}</TableCell>
                        {/* <TableCell align="right"
                          style={{ background: "gainsboro", borderRadius: "12px" }}
                        >
                          {item?.ans}
                        </TableCell> */}
                        <TableCell align="right">
                          {item?.result}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <div className="pageBtn">
              <button
                onClick={() => setPageRes(pageRes - 1)}
                disabled={pageRes === 1}
              >
                <i className="fa fa-angle-left"></i>
              </button>
              <button onClick={() => setPageRes(pageRes + 1)}>
                <i className="fa fa-angle-right"></i>
              </button>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="resCont">
            <div className="winRecord">
              <i className="fa fa-trophy"></i>
              <h5>All Records</h5>
            </div>
            <div className="tableAll">
              <table>
                <thead>
                  <tr>
                    <th style={{ padding: "14px" }}>User</th>
                    <th style={{ padding: "14px" }}>Bet</th>
                    <th style={{ padding: "14px" }}>Multi.</th>
                    <th style={{ padding: "14px" }}>Cash Out</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      {randomData?.map((item) => {
                        return (
                          <>
                            <div style={{ padding: "10px", margin: "1px" }}>
                              <i className="fa fa-user"
                                style={{ fontSize: "15px" }}
                              >
                              </i> &nbsp;
                              {item}
                            </div>
                          </>
                        )
                      })}
                    </td>
                    {randomData2?.map((item) => {
                      return (
                        <>
                          <div style={{ padding: "10px", margin: "1px" }}>
                            &#8377;{item}
                          </div>
                        </>
                      )
                    })}
                    <td>
                      {randomData2?.map(() => {
                        return (
                          <>
                            <div style={{ padding: "10px", margin: "1px" }}>
                              --
                            </div>
                          </>
                        )
                      })}
                    </td>
                    <td>
                      {randomData2?.map(() => {
                        return (
                          <>
                            <div style={{ padding: "10px", margin: "1px" }}>
                              --
                            </div>
                          </>
                        )
                      })}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </TabPanel>
        <div className="betCont">
          <div className="winRecord">
            <i className="fa fa-book"></i>
            <h5>My Record</h5>
          </div>
          {/* <div className="rewardText">
          <h5>No Data Available</h5>
        </div> */}
          <Paper>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                {betdata?.map((item) => {
                  return (
                    <>
                      <Accordion
                        expanded={expanded === item}
                        onChange={handleChange(item)}
                        sx={{
                          background: "linear-gradient(180deg, #ce0204, #f2403a)", marginBottom: "2px"
                        }}
                      >
                        <TableHead>
                          <TableRow className="betIcon">
                            <AccordionSummary
                              expandIcon={
                                <div className="expandIcon2">
                                  <i className="fa fa-angle-down"></i>
                                </div>
                              }
                              aria-controls="panel1bh-content"
                              id="panel1bh-header"
                              sx={{
                                display: "grid",
                                gridTemplateColumns: "98fr 2fr",
                                background: "linear-gradient(180deg, #ce0204, #f2403a)"
                              }}
                            >
                              <Typography
                                sx={{
                                  paddingLeft: "1px",
                                  flexShrink: 0,
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#fff"
                                }}
                              >
                                {item?.period}
                              </Typography>
                              <Typography
                                sx={{
                                  marginLeft: "15px",
                                  fontSize: "14px",
                                  fontWeight: "600",
                                }}
                              >
                                {item?.status}
                              </Typography>
                            </AccordionSummary>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <AccordionDetails
                              sx={{
                                background: "#fff",
                                color: "#000"
                              }}>
                              <div className="betHead">
                                <h4>Period Detail</h4>
                              </div>
                              <div className="betRecord">
                                <h6>Period</h6>
                                <h6>{item?.period}</h6>
                              </div>
                              <div className="betRecord">
                                <h6>Contract Money</h6>
                                <h6>{item?.amount}</h6>
                              </div>
                              <div className="betRecord">
                                <h6>Delivery</h6>
                                <h6>
                                  {item?.amount - (item?.amount * 5) / 100}
                                </h6>
                              </div>
                              <div className="betRecord">
                                <h6>Fee</h6>
                                <h6>{(item?.amount * 5) / 100}</h6>
                              </div>
                              <div className="betRecord">
                                <h6>Result</h6>
                                <h6>
                                  {item?.res}
                                </h6>
                              </div>
                              <div className="betRecord">
                                <h6>Select</h6>
                                <h6>{item?.ans}</h6>
                              </div>
                              <div className="betRecord">
                                <h6>Status</h6>
                                <h5>{item?.status}</h5>
                              </div>
                              <div className="betRecord">
                                <h6>Amount</h6>
                                <h6>{item?.price}</h6>
                              </div>
                              <div className="betRecord">
                                <h6>Create Time</h6>
                                <h6>{item?.time}</h6>
                              </div>
                            </AccordionDetails>
                          </TableRow>
                        </TableBody>
                      </Accordion>
                    </>
                  );
                })}
              </Table>
            </TableContainer>
          </Paper>
          <div className="pageBtn">
            <button onClick={() => setPage(page - 1)} disabled={page === 1}>
              <i className="fa fa-angle-left"></i>
            </button>
            <button onClick={() => setPage(page + 1)}>
              <i className="fa fa-angle-right"></i>
            </button>
          </div>
        </div>
        <div className="audio">
          {minute === 0 && second > 5 && second < 31 && (
            <ReactAudioPlayer
              src="316931__timbre__lever-pull-one-armed-bandit-from-freesound-316887-by-ylearkisto.flac"
              loop={true}
              autoPlay
              controls
            />
          )}
          {minute === 0 && second < 6 && (
            <ReactAudioPlayer
              src="cash-register-purchase-87313.mp3"
              autoPlay
              controls
            />
          )}
        </div>
      </div>
    </>
  );
}

/* LuckyHit CSS */
/* Win CSS */
