import { useNavigate } from "react-router-dom";
import BottomNav from "../BottomNavigate/BottomNav";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { Fetch_Url } from "../../Context/Context";
// mui
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 230,
  bgcolor: "white",
  p: 4,
  boxShadow: 24,
};

export default function Profile() {
  const navigate = useNavigate();
  // const [open, setOpen] = useState(false);
  // const [open2, setOpen2] = useState(false);
  const [notify, setNotify] = useState(false);
  const [logoutModal, setLogoutModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expanded2, setExpanded2] = useState(false);
  const [expanded3, setExpanded3] = useState(false);
  const [expanded4, setExpanded4] = useState(false);
  const [userdetail, setUserdetail] = useState([]);

  const logout = () => {
    navigator.vibrate(100);
    localStorage.removeItem("loginData") || navigate("/signin");
  };

  const getUserinfo = () => {
    axios
      .post(
        `${Fetch_Url}/me.php?action=getuserinfo&user=${window?.$loginData}`,
        {}
      )
      .then((resp) => {
        // console.log(resp, "resp data");
        setUserdetail(resp?.data?.user_Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleVIP = () => {
    navigate('/VipIndex');
  };

  const [bal, noticeInfo] = userdetail;
  // console.log(bal, noticeInfo, "bal, noticeInfo");

  useEffect(() => {
    getUserinfo();
  }, []);

  return (
    <>
      <BottomNav />
      <div className="profileBox">
        <div className="profileHead">
          <div className="profileImg">
            <img src="happy/Avator4.png" alt="" />
          </div>
          <div className="profileText">
            <h3>User : {bal?.username}</h3>
            <h3>ID : {bal?.usercode}</h3>
          </div>
          <div
            className="notifyIcon"
            onClick={() => {
              navigator.vibrate(100);
              setNotify(true);
            }}
          >
            <i className="fa fa-bell"></i>
          </div>
          <Modal
            open={notify}
            onClose={() => {
              setNotify(false);
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div className="signinModalTxt">
                <h2>Notice</h2>
              </div>
              <div className="signinModal">
                <h5>{noticeInfo?.notice}</h5>
              </div>
              <div className="notifyBtn">
                <button
                  onClick={() => {
                    setNotify(false);
                  }}
                >
                  CLOSE
                </button>
              </div>
            </Box>
          </Modal>
        </div>
        <div className="whiteBox">
          <div className="balBox">
            <h4>Total Balance</h4>
            <h3>&#x20b9;{bal?.balance}</h3>
          </div>
          <div className="balIcons">
            {/* <div className="balIconInfo" onClick={() => { navigate("/reward") }}> */}
            <div className="balIconInfo" onClick={() => { navigate("/Wallet") }}>
              {/* <CreditScoreIcon /> */}
              <img src="happy/image2.png" style={{ width: "32.5px", marginBottom: "5px" }} />
              <p>Wallet</p>
            </div>
            <div className="balIconInfo" onClick={() => { navigate("/recharge", { state: bal }) }}>
              {/* <PaidIcon /> */}
              <img src="happy/image1.png" style={{ width: "35px", marginBottom: "5px" }} />
              <p>Deposit</p>
            </div>
            <div className="balIconInfo" onClick={() => { navigate("/withdrawal", { state: bal }) }}>
              {/* <PaymentIcon /> */}
              <img src="happy/image3.png" style={{ width: "35px", marginBottom: "5px" }} />
              <p>Withdraw</p>
            </div>
            <div className="balIconInfo" onClick={handleVIP}>
              {/* <LocalAtmIcon /> */}
              <img src="happy/image4.png" style={{ width: "35px", marginBottom: "5px" }} />
              <p>VIP</p>
            </div>
          </div>
        </div>
      </div>
      {/* <br /> */}
      <div className="px-2">
        <hr />
        <div class="notification-container">
          <div class="icon-box">
            <img src="happy/Notification.png" style={{ width: "23px", height: "23px" }} />
          </div>
          <span class="notification-text">New Notifications</span>
          <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="16px" height="16px">
            <path d="M8.59 16.34L13.17 12l-4.58-4.34L10 6.5 16 12l-6 5.5z" />
          </svg>
        </div>
        <hr />
        <div class="">
          <a href="https://t.me/Games_350" target="_blank" className="notification-container">
            <div class="icon-box">
              <img src="happy/Telegram.png" style={{ width: "23px", height: "23px" }} /> 
            </div>
            <span class="notification-text">Join Telegram</span>
            <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="16px" height="16px">
              <path d="M8.59 16.34L13.17 12l-4.58-4.34L10 6.5 16 12l-6 5.5z" />
            </svg>
          </a>
        </div>
        <hr />
        <div class="notification-container" onClick={() => navigate('/bankcard')}>
          <div class="icon-box">
            <img src="happy/Bank.png" style={{ width: "23px", height: "23px" }} />
          </div>
          <span class="notification-text">Bank Card</span>
          <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="16px" height="16px">
            <path d="M8.59 16.34L13.17 12l-4.58-4.34L10 6.5 16 12l-6 5.5z" />
          </svg>
        </div>
        <hr />
        <div class="notification-container" onClick={() => navigate('/promotion')}>
          <div class="icon-box">
            <img src="happy/Promotion.png" style={{ width: "23px", height: "23px" }} />
          </div>
          <span class="notification-text">Promotion</span>
          <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="16px" height="16px">
            <path d="M8.59 16.34L13.17 12l-4.58-4.34L10 6.5 16 12l-6 5.5z" />
          </svg>
        </div>
        <hr />
        <div class="notification-container" onClick={() => navigate('/Wallet')}>
          <div class="icon-box">
            <img src="happy/image1.png" style={{ width: "23px", height: "23px" }} />
          </div>
          <span class="notification-text">Wallet</span>
          <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="16px" height="16px">
            <path d="M8.59 16.34L13.17 12l-4.58-4.34L10 6.5 16 12l-6 5.5z" />
          </svg>
        </div>
        <hr />
        <div class="notification-container" onClick={() => navigate('/Gift')}>
          <div class="icon-box">
            <img src="happy/Gifts.png" style={{ width: "23px", height: "23px" }} />
          </div>
          <span class="notification-text">Gifts</span>
          <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="16px" height="16px">
            <path d="M8.59 16.34L13.17 12l-4.58-4.34L10 6.5 16 12l-6 5.5z" />
          </svg>
        </div>
        <hr />
        <div class="language-container">
          <div class="icon-box">
            <img src="happy/Language.png" style={{ width: "23px", height: "23px" }} />
          </div>
          <span class="label">Language</span>
          <span class="value">English</span>
          <svg class="arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="16px" height="16px">
            <path d="M8.59 16.34L13.17 12l-4.58-4.34L10 6.5 16 12l-6 5.5z" />
          </svg>
        </div>
        <hr />
      </div>
      
      <div className="profileCont">
        {/* <div className="profileList">
          <a href="https://t.me/Games_350" target="_blank">
            <div className="listBox">
              <i className="fab fa-telegram"></i>
              <h4>Join Telegram</h4>
              <i className="fa fa-angle-down"></i>
            </div>
          </a>
          <Link to="/promotion">
            <div
              className="listBox"
            >
              <i className="fa fa-gift"></i>
              <h4>Promotion</h4>
              <i className="fa fa-angle-down"></i>
            </div>
          </Link>
          <div>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              className="proBar"
              sx={{ background: "white" }}
            >
              <AccordionSummary
                expandIcon={
                  <div className="expandIcon">
                    <i className="fa fa-angle-down"></i>
                  </div>
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ background: "white" }}
              >
                <Typography
                  sx={{
                    width: "35px",
                    paddingLeft: "1px",
                    flexShrink: 0,
                    fontSize: "20px",
                  }}
                >
                  <i className="fa fa-wallet"></i>
                </Typography>
                <Typography
                  sx={{
                    paddingTop: "3px",
                    fontWeight: "600",
                    color: "gray",
                  }}
                >
                  Wallet
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{ background: "white" }}
              >
                <Typography>
                  <Link to="/Wallet" state={bal}>
                    <div className="miniListBox">
                      <h4>Wallet</h4>
                    </div>
                  </Link>
                  <Link to="/recharge" state={bal}>
                    <div className="miniListBox">
                      <h4>Recharge</h4>
                    </div>
                  </Link>
                  <Link to="/withdrawal" state={bal}>
                    <div className="miniListBox">
                      <h4>Withdrawal</h4>
                    </div>
                  </Link>
                  <Link to="/transactions">
                    <div className="miniListBox">
                      <h4>Transactions</h4>
                    </div>
                  </Link>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <Link to="/bankcard">
            <div className="listBox">
              <i className="fa fa-credit-card"></i>
              <h4>Bank Card</h4>
              <i className="fa fa-angle-down"></i>
            </div>
          </Link>
          <div>
            <Accordion
              expanded={expanded2 === "panel2"}
              onChange={handleChange2("panel2")}
            >
              <AccordionSummary
                expandIcon={
                  <div className="expandIcon">
                    <i className="fa fa-angle-down"></i>
                  </div>
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "35px",
                    paddingLeft: "1px",
                    color: "gray",
                    flexShrink: 0,
                    fontSize: "20px",
                  }}
                >
                  <i className="fa fa-shield-alt"></i>
                </Typography>
                <Typography
                  sx={{
                    paddingTop: "3px",
                    fontWeight: "600",
                    color: "gray",
                  }}
                >
                  Account Security
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Link to="/forgotpassword">
                    <div className="miniListBox">
                      <h4>Reset Password</h4>
                    </div>
                  </Link>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div>
            <Accordion
              expanded={expanded3 === "panel3"}
              onChange={handleChange3("panel3")}
            >
              <AccordionSummary
                expandIcon={
                  <div className="expandIcon">
                    <i className="fa fa-angle-down"></i>
                  </div>
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "35px",
                    paddingLeft: "1px",
                    color: "gray",
                    flexShrink: 0,
                    fontSize: "20px",
                  }}
                >
                  <i className="fa fa-download"></i>
                </Typography>
                <Typography
                  sx={{
                    paddingTop: "3px",
                    fontWeight: "600",
                    color: "gray",
                  }}
                >
                  App Download
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="miniListBox">
                    <a
                      href="https://demo.games350.com/games350.apk"
                      download="games350"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <h4>Android Download</h4>
                    </a>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <Link to="/complaints">
            <div className="listBox">
              <i className="fa fa-file-text"></i>
              <h4>Complaints & Suggestions</h4>
              <i className="fa fa-angle-down"></i>
            </div>
          </Link>
          <div>
            <Accordion
              expanded={expanded4 === "panel4"}
              onChange={handleChange4("panel4")}
            >
              <AccordionSummary
                expandIcon={
                  <div className="expandIcon">
                    <i className="fa fa-angle-down"></i>
                  </div>
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "35px",
                    paddingLeft: "1px",
                    color: "gray",
                    flexShrink: 0,
                    fontSize: "20px",
                  }}
                >
                  <i className="fas fa-comment-alt"></i>
                </Typography>
                <Typography
                  sx={{
                    paddingTop: "3px",
                    fontWeight: "600",
                    color: "gray",
                  }}
                >
                  About
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="miniListBox">
                    <h4>Privacy Policy</h4>
                  </div>
                  <div className="miniListBox">
                    <h4>Risk Disclosure Agreement</h4>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div> */}
        <div className="serviceTxt">
          <h4>Service Center</h4>
        </div>
        <div className="allserviceIcons">
          <div className="serviceIcon">
            {/* <SettingsIcon /> */}
            <img src="happy/Setting.png" style={{ width: "32.5px", marginBottom: "5px" }} />
            <p>Settings</p>
          </div>
          <div className="serviceIcon">
            {/* <FeedbackIcon /> */}
            <img src="happy/Feedback.png" style={{ width: "32.5px", marginBottom: "5px" }} />
            <p>Feedback</p>
          </div>
          <div className="serviceIcon">
            {/* <SnoozeIcon /> */}
            <img src="happy/Notifications.png" style={{ width: "32.5px", marginBottom: "5px" }} />
            <p>Notification</p>
          </div>
          <div className="serviceIcon">
            {/* <SnoozeIcon /> */}
            <img src="happy/CustomersService.png" style={{ width: "32.5px", marginBottom: "5px" }} />
            <p>24/7 Customer Service</p>
          </div>
          <div className="serviceIcon">
            {/* <SnoozeIcon /> */}
            <img src="happy/BegineersGuide.png" style={{ width: "32.5px", marginBottom: "5px" }} />
            <p>Begineer's Guide</p>
          </div>
          <div className="serviceIcon" >
            {/* <InfoIcon /> */}
            <img src="happy/About.png" style={{ width: "32.5px", marginBottom: "5px" }} />
            <p>About Us</p>
          </div>
        </div>
        <div
          className="logoutBtn"
          onClick={() => {
            setLogoutModal(true);
          }}
        >
          <button>Logout</button>
        </div>
        <Modal
          open={logoutModal}
          onClose={() => {
            setLogoutModal(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="signinModalTxt">
              <h2>Confirm</h2>
            </div>
            <div className="signinModal">
              <h5>Do you want to Logout?</h5>
            </div>
            <div className="signinModalBtn">
              <button
                onClick={() => {
                  setLogoutModal(false);
                }}
              >
                CANCEL
              </button>
              <button onClick={logout}>YES</button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}

// Profile CSS
