import { useNavigate } from "react-router-dom";
import { Fetch_Url } from "../../../Context/Context";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";

export default function RechargeRecord() {
  const navigate = useNavigate();
  const [recordinfo, setRecordinfo] = useState([]);

  const recordData = () => {
    axios
      .post(
        `${Fetch_Url}/bet.php?action=rechargerecord&user=${window.$loginData}&page1=1`,
        {}
      )
      .then((resp) => {
        console.log(resp, "resp data");
        setRecordinfo(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    recordData();
  }, []);

  return (
    <>
      <div className="miniHead" style={{ background: "linear-gradient(180deg,#ce0204,#f2403a)" }}>
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <h3>Recharge Record</h3>
      </div>
      <div>
        {recordinfo.map((data) => {
          return (
            <>
              <div className="recordBox">
                <div className="recordText">
                  <div>
                    <h5>&#x20b9; {data?.recharge}</h5>
                  </div>
                  <div
                    className={
                      data?.status === "Failed" ? "redText" : "grayText"
                    }
                  >
                    <h5>{data?.status}</h5>
                  </div>
                </div>
                <div className="recordText">
                  <h5>{data?.rand}</h5>
                  <h5>EK pay</h5>
                </div>
                <div className="recordText">
                  <h5>{data?.created_at}</h5>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}

/* RechargeRecord CSS */
