import React, { useState, useEffect } from 'react';
import { wingo_Url } from '../../../Context/Context';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

export default function GameHistoryFiveMinute({ triggerApiGameHistoryFiveMinute }) {
    const [resdata4, setResdata4] = useState([]);
    const [pageRes4, setPageRes4] = useState(1);

    const resultRecord4 = React.useCallback(async () => {
        try {
            const response = await fetch(
                `${wingo_Url}/bet.php?action=resultrec_4&page=${pageRes4}`
            );
            const json = await response.json();
            setResdata4(json);
        } catch (error) {
            console.error('Error fetching result data:', error);
        }
    }, [pageRes4]);

    useEffect(() => {
        resultRecord4();
    }, [triggerApiGameHistoryFiveMinute, resultRecord4]);

    return (
        <div className="resCont">
            {/* <div className="winRecord">
                <i className="fa fa-trophy"></i>
                <h5>WinGo Record</h5>
            </div> */}
            <Paper>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Period</TableCell>
                                <TableCell align="right">Numbers</TableCell>
                                <TableCell align="right">Big Small</TableCell>
                                <TableCell align="right">Color</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {resdata4?.map((item) => (
                                <TableRow key={item?.name} sx={{ borderBottom: "2px solid rgb(255, 183, 50)" }}>
                                    <TableCell component="th" scope="row">
                                        {item?.period}
                                    </TableCell>
                                    <TableCell align="right">
                                        <div>
                                            <h4
                                                style={{ paddingTop: "6px" }}
                                                className={
                                                    item?.ans === "0"
                                                        ? "redColor"
                                                        : item?.ans === "2"
                                                        ? "redColor"
                                                        : item?.ans === "4"
                                                        ? "redColor"
                                                        : item?.ans === "6"
                                                        ? "redColor"
                                                        : item?.ans === "8"
                                                        ? "redColor"
                                                        : "greenColor"
                                                }
                                            >
                                                {item?.ans}
                                            </h4>
                                        </div>
                                    </TableCell>
                                    <TableCell align="right">
                                        {item?.ans <= 4 ? 'Small' : 'Big'}
                                    </TableCell>
                                    <TableCell align="right">
                                        <div className="tableColor">
                                            <h4
                                                className={
                                                    item?.clo === "red" ? "colorRed" : "colorGreen"
                                                }
                                            ></h4>
                                            <h4
                                                className={
                                                    item?.res1 === "violet" ? "colorViolet" : ""
                                                }
                                            ></h4>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
            <div className="GameHistorypageBtn mt-2">
                <button
                    onClick={() => setPageRes4(pageRes4 - 1)}
                    disabled={pageRes4 === 1}
                >
                    <i className="fa fa-angle-left"></i>
                </button>
                <button onClick={() => setPageRes4(pageRes4 + 1)}>
                    <i className="fa fa-angle-right"></i>
                </button>
            </div>
        </div>
    );
}
