import * as React from 'react';
import Slider from '@mui/material/Slider';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
// MUI
import Box from '@mui/material/Box';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { dice_Url } from '../../Context/Context';
import ReactAudioPlayer from "react-audio-player";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 320,
    bgcolor: "white",
    boxShadow: 24,
    borderRadius: "20px"
};

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const moneydata = [
    {
        id: 1,
        money: 10,
    },
    {
        id: 2,
        money: 100,
    },
    {
        id: 3,
        money: 1000,
    },
    {
        id: 4,
        money: 10000,
    },
];

const marks = [
    {
        value: 4,
        label: '4',
        multiply: 23.75
    },
    {
        value: 5,
        multiply: 19.00
    },
    {
        value: 6,
        multiply: 15.83
    },
    {
        value: 7,
        multiply: 13.57
    },
    {
        value: 8,
        multiply: 11.88
    },
    {
        value: 9,
        multiply: 10.56
    },
    {
        value: 10,
        multiply: 9.50
    },
    {
        value: 11,
        multiply: 8.64
    },
    {
        value: 12,
        multiply: 7.92
    },
    {
        value: 13,
        multiply: 7.31
    },
    {
        value: 14,
        multiply: 6.79
    },
    {
        value: 15,
        multiply: 6.33
    },
    {
        value: 16,
        multiply: 5.94
    },
    {
        value: 17,
        multiply: 5.59
    },
    {
        value: 18,
        multiply: 5.28
    },
    {
        value: 19,
        multiply: 5.00
    },
    {
        value: 20,
        multiply: 4.75
    },
    {
        value: 21,
        multiply: 4.52
    },
    {
        value: 22,
        multiply: 4.32
    },
    {
        value: 23,
        multiply: 4.13
    },
    {
        value: 24,
        multiply: 3.96
    },
    {
        value: 25,
        label: '25',
        multiply: 3.80
    },
    {
        value: 26,
        multiply: 3.65
    },
    {
        value: 27,
        multiply: 3.52
    },
    {
        value: 28,
        multiply: 3.39
    },
    {
        value: 29,
        multiply: 3.28
    },
    {
        value: 30,
        multiply: 3.17
    },
    {
        value: 31,
        multiply: 3.06
    },
    {
        value: 32,
        multiply: 2.97
    },
    {
        value: 33,
        multiply: 2.88
    },
    {
        value: 34,
        multiply: 2.79
    },
    {
        value: 35,
        multiply: 2.71
    },
    {
        value: 36,
        multiply: 2.64
    },
    {
        value: 37,
        multiply: 2.57
    },
    {
        value: 38,
        multiply: 2.50
    },
    {
        value: 39,
        multiply: 2.44
    },
    {
        value: 40,
        multiply: 2.38
    },
    {
        value: 41,
        multiply: 2.32
    },
    {
        value: 42,
        multiply: 2.26
    },
    {
        value: 43,
        multiply: 2.21
    },
    {
        value: 44,
        multiply: 2.16
    },
    {
        value: 45,
        multiply: 2.11
    },
    {
        value: 46,
        multiply: 2.07
    },
    {
        value: 47,
        multiply: 2.02
    },
    {
        value: 48,
        multiply: 1.98
    },
    {
        value: 49,
        multiply: 1.94
    },
    {
        value: 50,
        label: '50',
        multiply: 1.90
    },
    {
        value: 51,
        multiply: 1.86
    },
    {
        value: 52,
        multiply: 1.83
    },
    {
        value: 53,
        multiply: 1.79
    },
    {
        value: 54,
        multiply: 1.76
    },
    {
        value: 55,
        multiply: 1.73
    },
    {
        value: 56,
        multiply: 1.70
    },
    {
        value: 57,
        multiply: 1.67
    },
    {
        value: 58,
        multiply: 1.64
    },
    {
        value: 59,
        multiply: 1.61
    },
    {
        value: 60,
        multiply: 1.58
    },
    {
        value: 61,
        multiply: 1.56
    },
    {
        value: 62,
        multiply: 1.53
    },
    {
        value: 63,
        multiply: 1.51
    },
    {
        value: 64,
        multiply: 1.48
    },
    {
        value: 65,
        multiply: 1.46
    },
    {
        value: 66,
        multiply: 1.44
    },
    {
        value: 67,
        multiply: 1.42
    },
    {
        value: 68,
        multiply: 1.40
    },
    {
        value: 69,
        multiply: 1.38
    },
    {
        value: 70,
        multiply: 1.36
    },
    {
        value: 71,
        multiply: 1.34
    },
    {
        value: 72,
        multiply: 1.32
    },
    {
        value: 73,
        multiply: 1.30
    },
    {
        value: 74,
        multiply: 1.28
    },
    {
        value: 75,
        label: '75',
        multiply: 1.27
    },
    {
        value: 76,
        multiply: 1.25
    },
    {
        value: 77,
        multiply: 1.23
    },
    {
        value: 78,
        multiply: 1.22
    },
    {
        value: 79,
        multiply: 1.20
    },
    {
        value: 80,
        multiply: 1.19
    },
    {
        value: 81,
        multiply: 1.17
    },
    {
        value: 82,
        multiply: 1.16
    },
    {
        value: 83,
        multiply: 1.14
    },
    {
        value: 84,
        multiply: 1.13
    },
    {
        value: 85,
        multiply: 1.12
    },
    {
        value: 86,
        multiply: 1.10
    },
    {
        value: 87,
        multiply: 1.09
    },
    {
        value: 88,
        multiply: 1.08
    },
    {
        value: 89,
        multiply: 1.07
    },
    {
        value: 90,
        multiply: 1.06
    },
    {
        value: 91,
        multiply: 1.04
    },
    {
        value: 92,
        label: '92',
        multiply: 1.03
    },
];

export default function Dice() {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [rule, setRule] = React.useState(false);
    const [num, setNum] = React.useState(50)
    const [value, setValue] = React.useState(0);
    const [seconds, setSeconds] = React.useState(60);
    const [minute, setMinute] = React.useState(0);
    const [second, setSecond] = React.useState(0);
    const [userdetailWin, setUserdetailWin] = React.useState([]);
    const [resultinfo, setResultinfo] = React.useState([]);
    const [randomData, setRandomData] = React.useState([]);
    const [randomData2, setRandomData2] = React.useState([]);
    const [betdata, setBetdata] = React.useState([]);
    const [recordinfo, setRecordinfo] = React.useState([]);
    const [page, setPage] = React.useState(1);
    const [page2, setPage2] = React.useState(1);
    const [intvalue, setIntvalue] = React.useState(1);
    const [greenbtn, setGreenbtn] = React.useState({
        id: 1,
        money: 10,
    });

    let contractMoney = greenbtn?.money * intvalue;
    // console.log(contractMoney, "contractMoney");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    let findMultiply = marks?.find((item) => {
        return item?.value == num
    })
    // console.log(findMultiply?.multiply, "findMultiply")

    const userInfo = () => {
        axios
            .post(`${dice_Url}/bet.php?action=info&user=${window.$loginData}`, {})
            .then((resp) => {
                console.log(resp, "resp userInfo data");
                setUserdetailWin(resp?.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const [bal] = userdetailWin;
    // console.log(bal, "bal");

    const periodID = userdetailWin?.find((item) => {
        return item?.period;
    });
    // console.log(periodID, "periodID");

    const diceResult = () => {
        axios
            .post(`${dice_Url}/declaredResult.php`, {
                aksi: "proses_random_less_than",
                period: periodID?.period,
                Username: window?.$loginData
            })
            .then((resp) => {
                console.log(resp, "resp diceResult data");
                setResultinfo(resp?.data?.UserBetRecord?.BetRecord);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const updatePeriod = () => {
        axios
            .post(`${dice_Url}/declaredResult.php`, {
                aksi: "Update_PeriodId"
            })
            .then((resp) => {
                console.log(resp, "resp updatePeriod data");
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const betClick = () => {
        navigator.vibrate(100);
        const formdata = new FormData();
        formdata.append("username", window.$loginData);
        formdata.append("period", periodID?.period);
        formdata.append("amount", contractMoney);
        formdata.append("less_than", num);
        formdata.append("multiply", findMultiply?.multiply);
        if (contractMoney <= bal?.balance) {
            axios
                .post(`${dice_Url}/bet.php?action=bet`, formdata, {})
                .then((resp) => {
                    console.log(resp, "betClick resp data");
                    if (resp?.data?.status === "Bet Added Successfully") {
                        toast.success(resp?.data?.status, {
                            position: toast.POSITION.BOTTOM_LEFT,
                        });
                        setOpen(false);
                        userInfo()
                        betRecord()
                    }
                    else {
                        setOpen(false);
                        toast.error(resp?.data?.status, {
                            position: toast.POSITION.BOTTOM_LEFT,
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            toast.error("Insufficient Balance", {
                position: toast.POSITION.BOTTOM_LEFT,
            });
        }
    };

    const betRecord = React.useCallback(async () => {
        const res = fetch(
            `${dice_Url}/bet.php?action=betrec&user=${window?.$loginData}&page1=${page}`
        ).then((res) => res.json());
        const json = await res;
        console.log(json, "res bet data");
        setBetdata(json);
    }, [page]);

    React.useEffect(() => {
        betRecord();
    }, [betRecord]);

    const allRecord = React.useCallback(async () => {
        const res = fetch(
            `${dice_Url}/bet.php?action=resultrec&page=${page2}`
        ).then((res) => res.json());
        const json = await res;
        console.log(json, "res allRecord data");
        setRecordinfo(json);
    }, [page2]);

    React.useEffect(() => {
        allRecord();
    }, [allRecord]);

    const generateRandomData = () => {
        const newData = [];
        const newData2 = [];
        for (let i = 0; i < 30; i++) {
            // Generate your random data here
            const randomValue = (Math?.floor(100000 + Math?.random() * 900000));
            const randomValue2 = (Math?.floor(1000 + Math?.random() * 9000));
            newData?.push(randomValue);
            newData2?.push(randomValue2);
        }
        setRandomData(newData);
        setRandomData2(newData2);
    };

    React.useEffect(() => {
        let interval = null;

        if (seconds > 0) {
            interval = setInterval(() => {
                setSeconds(seconds - 1);
            }, 1000);
        }
        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    React.useEffect(() => {
        const DateValue = Date.parse(new Date()) / 1e3;
        const h = 60 - (DateValue % 60);
        var i = h / 60,
            n = h % 60;
        setMinute(Math.floor(i));
        setSecond(("0" + Math.floor(n)).slice(-2));
    });
    // console.log(minute, second, "minute, second");

    React.useEffect(() => {
        if (minute === 0 && second < "11") {
            setOpen(false)
        }
        if ((minute === 0 && second === "05") && (resultinfo?.period !== null)) {
            setOpen2(true);
        }
        if (minute === 0 && second === "10") {
            diceResult()
        }
        if (minute === 0 && second === "01") {
            updatePeriod()
        }
        if (minute === 1 && second === "00") {
            window?.location?.reload(false);
        }
    }, [minute, second]);

    React.useEffect(() => {
        userInfo();
        generateRandomData()
    }, []);

    return (
        <>
            <ToastContainer />
            <div className="diceHead" style={{ background: "linear-gradient(180deg,#ce0204,#f2403a)" }}>
                <i className="fa fa-arrow-left"
                    onClick={() => {
                        navigate(-1)
                    }}
                ></i>
                <h3>Dice</h3>
                <h4>
                    &#8377;{bal?.balance}
                </h4>
                <i
                    className="fa fa-credit-card"
                    onClick={() => {
                        navigate("/recharge", { state: bal }
                        );
                    }}
                ></i>
                <h4 onClick={() => {
                    setRule(true)
                }}>Rule</h4>
                <Modal
                    open={rule}
                    onClose={() => {
                        setRule(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="diceRule">
                            <h2>Dice Rule</h2>
                        </div>
                        <div className="diceRuleInfo">
                            <h4>1 minutes 1 issue, 50 seconds to order, 10 seconds to show the result. It opens all day. The total number of trade is 1440 issues.</h4>
                            <button onClick={() => {
                                setRule(false)
                            }}>GOT IT</button>
                        </div>
                    </Box>
                </Modal>
            </div>
            <div className='diceInfo'>
                <div className="diceBox">
                    <div className="dicePeriod">
                        <h5>Period</h5>
                        <h3>
                            {periodID?.period}
                        </h3>
                    </div>
                    <div className="diceCount">
                        <h5>Count Down</h5>
                        <h3>
                            <span>
                                0{minute}
                            </span>
                            <span>:</span>
                            <span>
                                {second}
                            </span>
                        </h3>
                    </div>
                </div>
                <div className='diceAllCard'>
                    <div className='diceCard'>
                        <h4>Less than</h4>
                        <h3>{num}</h3>
                    </div>
                    <div className='diceCard'>
                        <h4>Multiplier</h4>
                        <h3>{findMultiply?.multiply}</h3>
                    </div>
                </div>
                <Box sx={{ width: "100%", mt: 2 }}>
                    <Slider
                        defaultValue={50}
                        aria-label="Default"
                        valueLabelDisplay="auto"
                        marks={marks}
                        min={4}
                        max={92}
                        sx={{  color: "#f2413b", }}
                        onChange={(e) => {
                            setNum(e.target.value)
                        }}
                    />
                </Box>
                <div className='diceBtn'>
                    <button
                        onClick={() => {
                            minute === 0 && second < "10"
                                ? setOpen(false)
                                : setOpen(true);
                        }}
                    >Less than {num}</button>
                </div>
                <Modal
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="diceBlueHead">
                            <h2>Less than {num}</h2>
                        </div>
                        <div className="greenHeadBox">
                            <div className="greenHeadTxt">
                                <h5>Contract Money</h5>
                            </div>
                            <div className="greenHeadBtn">
                                {moneydata?.map((item) => {
                                    return (
                                        <>
                                            <div
                                                className={
                                                    item?.id === greenbtn?.id ? "clickedColor" : ""
                                                }
                                            >
                                                <button
                                                    onClick={() => {
                                                        setGreenbtn(item);
                                                    }}
                                                >
                                                    {item?.money}
                                                </button>
                                            </div>
                                        </>
                                    );
                                })}
                            </div>
                            <div className="greenHeadTxt">
                                <h5>Number</h5>
                            </div>
                            <div className="greenHeadCount">
                                <button
                                    disabled={intvalue <= 1}
                                    onClick={() => {
                                        setIntvalue(intvalue - 1);
                                    }}
                                >
                                    <i className="fa fa-minus"></i>
                                </button>
                                <h3>{intvalue}</h3>
                                <button
                                    onClick={() => {
                                        setIntvalue(intvalue + 1);
                                    }}
                                >
                                    <i className="fa fa-plus"></i>
                                </button>
                            </div>
                            <div className="greenHeadTxt">
                                <h5>Total Contract Money is {contractMoney}</h5>
                            </div>
                            <div className="greenHeadTxt2">
                                <h4>
                                    <i className="fa fa-check"></i>I agree
                                    <span>PRESALE RULE</span>
                                </h4>
                            </div>
                            <div className="signinModalBtn">
                                <button
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    CANCEL
                                </button>
                                <button onClick={betClick}>CONFIRM</button>
                            </div>
                        </div>
                    </Box>
                </Modal>
            </div>
            <div className='diceRecordBox'>
                <div className='diceRecordHead'>
                    <h3>Record</h3>
                </div>
                <div className='diceRecord'>
                    {recordinfo?.map((data) => {
                        return (
                            <>
                                <div className='diceOne'>
                                    <h4>{data?.result}</h4>
                                    <h5>{data?.Last_periodId}</h5>
                                </div>
                            </>
                        )
                    })}
                </div>
                <div className="pageBtn">
                    <button
                        onClick={() => setPage2(page2 - 1)}
                        disabled={page2 === 1}
                    >
                        <i className="fa fa-angle-left"></i>
                    </button>
                    <button onClick={() => setPage2(page2 + 1)}>
                        <i className="fa fa-angle-right"></i>
                    </button>
                </div>
            </div>
            <Box
                sx={{
                    width: "100%",
                    marginTop: "15px"
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    className="diceTabs"
                >
                    <Tab label="Dice Records" {...a11yProps(0)} />
                    <Tab label="All Records" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <div className='diceTable'>
                    <table>
                        <thead>
                            <tr>
                                <th>Period</th>
                                <th>Select</th>
                                <th>Result</th>
                                <th>Status</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {betdata?.map((item) => {
                                return (
                                    <>
                                        <tr>
                                            <td>
                                                {item?.period}
                                            </td>
                                            <td><span>{item?.less_than}</span></td>
                                            <td><span>{item?.res}</span></td>
                                            <td><span>{item?.status}</span></td>
                                            <td>{item?.amount}</td>
                                        </tr>
                                    </>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="pageBtn">
                    <button
                        onClick={() => setPage(page - 1)}
                        disabled={page === 1}
                    >
                        <i className="fa fa-angle-left"></i>
                    </button>
                    <button onClick={() => setPage(page + 1)}>
                        <i className="fa fa-angle-right"></i>
                    </button>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div className='diceTable'>
                    <table>
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Bet</th>
                                <th>Multi.</th>
                                <th>Cash Out</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {randomData?.map((item) => {
                                        return (
                                            <>
                                                <div style={{ marginBottom: '15px' }}>
                                                    <i className="fa fa-user">
                                                    </i> &nbsp;
                                                    {item}
                                                </div>
                                            </>
                                        )
                                    })}
                                </td>
                                {randomData2?.map((item) => {
                                    return (
                                        <>
                                            <div style={{ color: "white", marginBottom: '15px' }}>
                                                <span>&#8377;{item}</span>
                                            </div >
                                        </>
                                    )
                                })}
                                <td>
                                    {randomData2?.map(() => {
                                        return (
                                            <>
                                                <div style={{ marginBottom: '15px' }}>
                                                    -
                                                </div>
                                            </>
                                        )
                                    })}
                                </td>
                                <td>
                                    {randomData2?.map(() => {
                                        return (
                                            <>
                                                <div style={{ marginBottom: '15px' }}>
                                                    -
                                                </div>
                                            </>
                                        )
                                    })}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </TabPanel >
            {open2 &&
                <Modal
                    open={open2}
                    onClose={() => {
                        setOpen2(false);
                    }}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className='diceResult'>
                            <h2>{resultinfo?.status === "Success" ? "Win" : "Loss"}</h2>
                            <h3>{resultinfo?.res}</h3>
                        </div>
                        <div className='diceOutput'>
                            <h4>Period</h4>
                            <h4>{resultinfo?.period}</h4>
                        </div>
                        <div className='diceOutput'>
                            <h4>Price</h4>
                            <h4>{resultinfo?.price}</h4>
                        </div>
                        <div className='diceAllout'>
                            <div className='diceOutput'>
                                <h4>Select</h4>
                                <h4>{resultinfo?.less_than}</h4>
                            </div>
                            {/* <div className='diceOutput'>
                                <h4>Point</h4>
                                <h4>10</h4>
                            </div> */}
                            <div className='diceOutput'>
                                <h4>Amount</h4>
                                <h4>{resultinfo?.amount}</h4>
                            </div>
                        </div>
                        <div className='diceClose'>
                            <button onClick={() => { setOpen2(false) }}>Close</button>
                        </div>
                    </Box>
                </Modal>
            }
            <div className="audio">
                {minute === 0 && second < 11 && (
                    <ReactAudioPlayer
                        src="WhatsApp Audio 2023-09-19 at 02.12.53 (1).mpeg"
                        autoPlay
                        controls
                    />
                )}
            </div>
        </>
    )
}

/* Dice CSS */
