import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { Fetch_Url } from "../../../../Context/Context";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AddBankCard() {
  const navigate = useNavigate();
  const [actualname, setActualname] = useState([]);
  const [upi, setUpi] = useState([]);
  // const [otp, setOtp] = useState([]);
  // const [bankname, setBankname] = useState([]);
  // const [ifsc, setIfsc] = useState([]);
  // const [account, setAccount] = useState([]);
  // const [state, setState] = useState([]);
  // const [city, setCity] = useState([]);
  // const [address, setAddress] = useState([]);
  // const [mobile, setMobile] = useState([]);
  // const [email, setEmail] = useState([]);

  // const getOtp = () => {
  //   navigator.vibrate(100);
  //   axios
  //     .post(`${Fetch_Url}/otp.php?num=${window.$loginData}`, {})
  //     .then((resp) => {
  //       // console.log(resp, "getOTP Data");
  //       toast.success("OTP Sent", {
  //         position: toast.POSITION.BOTTOM_LEFT,
  //       });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const bankData = () => {
    navigator.vibrate(100);
    const formdata = new FormData();
    formdata?.append("actualname", actualname);
    formdata?.append("upi", upi);
    // formdata?.append("code", otp);
    // formdata?.append("account", account);
    // formdata?.append("ifsc", ifsc);
    // formdata?.append("bankname", bankname);
    // formdata?.append("state", state);
    // formdata?.append("city", city);
    // formdata?.append("address", address);
    // formdata?.append("mobile", mobile);
    // formdata?.append("email", email);
    // mobile?.length === 10 &&
    // account?.length > 0 &&
    // ifsc?.length > 0 &&
    // bankname?.length > 0 &&
    // state?.length > 0 &&
    // city?.length > 0 &&
    // address?.length &&
    // email?.length > 0 &&
    // otp?.length > 0 &&
    actualname?.length > 0 && upi?.length > 0 
      ? axios
          .post(
            `${Fetch_Url}/bet.php?action=addbankcard&user=${window.$loginData}`,
            formdata,
            {}
          )
          .then((resp) => {
            console.log(resp, "resp data");
            resp?.data[0]?.status === "Incorrect otp"
              ? toast.error("Incorrect OTP", {
                  position: toast.POSITION.BOTTOM_LEFT,
                })
              : toast.success("Bank Card Add Successfully", {
                  position: toast.POSITION.BOTTOM_LEFT,
                }) &&
                setTimeout(() => {
                  navigate("/bankcard");
                }, 1500);
          })
          .catch((err) => {
            console.log(err);
          })
      : toast.error("Input Empty", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
  };

  return (
    <>
      <ToastContainer />
      <div className="miniHead" style={{ background: "linear-gradient(180deg,#ce0204,#f2403a)" }}>
        <i
          className="fa fa-arrow-left"
          onClick={() => {
            navigate(-1);
          }}
        ></i>
        <h3>Add Bank Card</h3>
      </div>
      <div>
        <div className="bankInput">
          <label>Actual Name</label>
          <input
            type="text"
            onChange={(e) => {
              setActualname(e.target.value);
            }}
          />
        </div>
        <div className="bankInput">
          <label>UPI ID</label>
          <input
            type="text"
            onChange={(e) => {
              setUpi(e.target.value);
            }}
          />
        </div>
        {/* <div className="bankInput">
          <label>IFSC Code</label>
          <input
            type="text"
            onChange={(e) => {
              setIfsc(e.target.value);
            }}
          />
        </div> */}
        {/* <div className="bankInput">
          <label>Bank Name</label>
          <input
            type="text"
            onChange={(e) => {
              setBankname(e.target.value);
            }}
          />
        </div> */}
        {/* <div className="bankInput">
          <label>Bank Account</label>
          <input
            type="text"
            onChange={(e) => {
              setAccount(e.target.value);
            }}
          />
        </div> */}
        {/* <div className="bankInput">
          <label>State/Territory</label>
          <input
            type="text"
            onChange={(e) => {
              setState(e.target.value);
            }}
          />
        </div> */}
        {/* <div className="bankInput">
          <label>City</label>
          <input
            type="text"
            onChange={(e) => {
              setCity(e.target.value);
            }}
          />
        </div> */}
        {/* <div className="bankInput">
          <label>Address</label>
          <input
            type="text"
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </div> */}
        {/* <div className="bankInput">
          <label>Mobile Number</label>
          <input
            type="text"
            onChange={(e) => {
              setMobile(e.target.value);
            }}
          />
        </div> */}
        {/* <div className="bankInput">
          <label>Email</label>
          <input
            type="text"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div> */}
        <div className="bankInput">
          <label>Account Phone Number</label>
          <input
            type="text"
            value={window.$loginData}
            readOnly={true}
            disabled
          />
        </div>
        {/* <div className="bankInput">
          <label>Code</label>
          <div className="otpInputCard">
            <input
              type="text"
              placeholder="Verification Code"
              onChange={(e) => {
                setOtp(e.target.value);
              }}
            />
            <h4 onClick={getOtp}>OTP</h4>
          </div>
        </div> */}
        <div className="signinBtnCard" onClick={bankData}>
          <button>Continue</button>
        </div>
      </div>
    </>
  );
}

// AddBankCard CSS
