import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

export default function Activity() {
    const navigate = useNavigate();

    return (
        <>
            <div className="minibg">
                <div className="miniHeadforWallet">
                    <i
                    className="fa fa-arrow-left"
                    onClick={() => {
                        navigate(-1);
                    }}
                    ></i>
                    <h3>Activity</h3>
                </div>
            </div>
            
            <div className="WalletbalCont">
                <div className=" wallet-grid">
                    <div className=" wallet-card" onClick={() => navigate('/DailyTasks')}>
                        <img
                            src="happy/RechargeIcon.png"
                            className=" wallet-image"
                        />
                        <p className=" wallet-title">
                            Activity
                        </p>
                    </div>
                    <div className=" wallet-card" onClick={() => navigate('/Withdrawal')}>
                        <img
                            src="happy/WithdrawalIcon.png"
                            className=" wallet-image"
                        />
                        <p className=" wallet-title">
                            Withdraw
                        </p>
                    </div>
                    <div className=" wallet-card" onClick={() => navigate('/RechargeRecord')}>
                        <img
                            src="happy/RechargeHistoryIcon.png"
                            className=" wallet-image"
                        />
                        <p className=" wallet-title">
                            Deposit History
                        </p>
                    </div>
                    <div className=" wallet-card" onClick={() => navigate('/WithRecord')}>
                        <img
                            src="happy/WithdrawalHistoryIcon.png"
                            className=" wallet-image"
                        />
                        <p className=" wallet-title">
                            Withdrawal History
                        </p>
                    </div>
                </div>
                <div class="px-2">
                    <div class="row">
                        <div class="col-6">
                            <div class="Activitycard" onClick={() => navigate('/Gift')}>
                                <img src="happy/GiftsBanner.png" alt="Gifts" />
                                <h3>Gifts</h3>
                                <p>Enter the redemption code to receive gift rewards</p>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="Activitycard">
                                <img src="happy/AttendanceBonus.png" alt="Attendance Bonus" />
                                <h3>Attendance Bonus</h3>
                                <p>The more consecutive days you sign in, the higher the reward will be.</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="Activitycard mt-3">
                        <img src="happy/AttendanceBonus.png" alt="Attendance Bonus" />
                        <h3 style={{ paddingBottom: "10px", paddingLeft: "20px" }}>💸 First Deposit Bonus 💸</h3>
                    </div>
                </div>
            </div>
        </>
    );
}